<template>
  <div>
    <a-card :title="$t('调拨单')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" :placeholder="$t('单据编号, 备注')" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" style="margin-bottom: 12px; width: 200px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <!-- <a-button icon="download" @click="exportSummaryExcel">{{ $t("汇总导出") }}</a-button> -->
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handleAdd(form)">{{ $t("新增调拨单") }}</a-button>
        </div>
      </a-row>

      <div>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="onChangeTable"
          :scroll="{ x: 1020 }"
        >
          <template slot="number" slot-scope="value, item, index">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
              <a-popconfirm :title="$t('确定作废吗?')" @confirm="voidItem(item)">
                <a-button type="danger">{{ $t("作废") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { exportExcel } from "@/utils/excel";
import { stockTransferOrdersExport, stockTransferOrderSummaryExport } from "@/api/export";
import { stockTransferOrderList, stockTransferOrderVoid } from "@/api/stockTransfer";
import { columns } from "./columns";
import moment from "moment";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 16, showTotal: (total) => `${this.$t("共")} ${total} ${this.$t("条")}` },
      searchForm: { page: 1, is_void: false },
      loading: false,
      items: [],
      selectedRowKeys: [],
      selectionRows: [],
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
    },
    handleAdd() {
      this.$router.push({ path: "/stock_transfer/order_create", query: {} });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      stockTransferOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    voidItem(item) {
      stockTransferOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    detail(item) {
      this.$router.push({ path: "/stock_transfer/order_detail", query: { id: item.id } });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的调拨单!");
        return false;
      }
      stockTransferOrdersExport({ stock_transfer_orders: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "调拨单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    exportSummaryExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的调拨单!");
        return false;
      }
      stockTransferOrderSummaryExport({ stock_transfer_orders: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "调拨单汇总");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
