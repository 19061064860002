import { T } from "@/locales";

export const columns = [
  {
    title: T("序号"),
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("调拨单号"),
    dataIndex: "number",
    sorter: true,
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: "number" },
  },
  {
    title: T("出库仓库"),
    dataIndex: "out_warehouse_name",
    width: 160,
    ellipsis: true,
  },
  {
    title: T("入库仓库"),
    dataIndex: "in_warehouse_name",
    width: 160,
    ellipsis: true,
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
    sorter: true,
    width: 180,
    ellipsis: true,
  },
  {
    title: T("备注"),
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
  },
  {
    title: T("操作"),
    dataIndex: "action",
    fixed: "right",
    width: 120,
    scopedSlots: { customRender: "action" },
  },
];
